* {
    outline: none
}

body {
    background: #202124;
    font-family: "Montserrat", sans-serif;
    margin: 0;
    overflow-x: hidden;
    color: #67757c;
    font-weight: 300
}

html {
    position: relative;
    min-height: 100%;
    background: #ffffff;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale
}

a:focus,
a:hover {
    text-decoration: none
}

a.link {
    color: #455a64
}

a.link:focus,
a.link:hover {
    color: #398bf7
}

.img-responsive {
    width: 100%;
    height: auto;
    display: inline-block
}

.img-rounded {
    border-radius: 4px
}

html body .mdi-set,
html body .mdi:before {
    line-height: initial
}

h1,
h2,
h3,
h4,
h5,
h6 {
    color: #455a64;
    font-family: "Montserrat", sans-serif;
    font-weight: 400
}

h1 {
    line-height: 40px;
    font-size: 36px
}

h2 {
    line-height: 36px;
    font-size: 24px
}

h3 {
    line-height: 30px;
    font-size: 21px
}

h4 {
    line-height: 22px;
    font-size: 18px
}

h5 {
    line-height: 18px;
    font-size: 16px;
    font-weight: 400
}

h6 {
    line-height: 16px;
    font-size: 14px;
    font-weight: 400
}

.display-5 {
    font-size: 3rem
}

.display-6 {
    font-size: 36px
}

.box {
    border-radius: 4px;
    padding: 10px
}

html body .dl {
    display: inline-block
}

html body .db {
    display: block
}

.no-wrap td,
.no-wrap th {
    white-space: nowrap
}

html body blockquote {
    border-left: 5px solid #398bf7;
    border: 1px solid rgba(120, 130, 140, 0.13);
    padding: 15px
}

.clear {
    clear: both
}

ol li {
    margin: 5px 0
}

html body .p-0 {
    padding: 0px
}

html body .p-10 {
    padding: 10px
}

html body .p-20 {
    padding: 20px
}

html body .p-30 {
    padding: 30px
}

html body .p-l-0 {
    padding-left: 0px
}

html body .p-l-10 {
    padding-left: 10px
}

html body .p-l-20 {
    padding-left: 20px
}

html body .p-r-0 {
    padding-right: 0px
}

html body .p-r-10 {
    padding-right: 10px
}

html body .p-r-20 {
    padding-right: 20px
}

html body .p-r-30 {
    padding-right: 30px
}

html body .p-r-40 {
    padding-right: 40px
}

html body .p-t-0 {
    padding-top: 0px
}

html body .p-t-10 {
    padding-top: 10px
}

html body .p-t-20 {
    padding-top: 20px
}

html body .p-t-30 {
    padding-top: 30px
}

html body .p-b-0 {
    padding-bottom: 0px
}

html body .p-b-5 {
    padding-bottom: 5px
}

html body .p-b-10 {
    padding-bottom: 10px
}

html body .p-b-20 {
    padding-bottom: 20px
}

html body .p-b-30 {
    padding-bottom: 30px
}

html body .p-b-40 {
    padding-bottom: 40px
}

html body .m-0 {
    margin: 0px
}

html body .m-l-5 {
    margin-left: 5px
}

html body .m-l-10 {
    margin-left: 10px
}

html body .m-l-15 {
    margin-left: 15px
}

html body .m-l-20 {
    margin-left: 20px
}

html body .m-l-30 {
    margin-left: 30px
}

html body .m-l-40 {
    margin-left: 40px
}

html body .m-r-5 {
    margin-right: 5px
}

html body .m-r-10 {
    margin-right: 10px
}

html body .m-r-15 {
    margin-right: 15px
}

html body .m-r-20 {
    margin-right: 20px
}

html body .m-r-30 {
    margin-right: 30px
}

html body .m-r-40 {
    margin-right: 40px
}

html body .m-t-0 {
    margin-top: 0px
}

html body .m-t-5 {
    margin-top: 5px
}

html body .m-t-10 {
    margin-top: 10px
}

html body .m-t-15 {
    margin-top: 15px
}

html body .m-t-20 {
    margin-top: 20px
}

html body .m-t-30 {
    margin-top: 30px
}

html body .m-t-40 {
    margin-top: 40px
}

html body .m-b-0 {
    margin-bottom: 0px
}

html body .m-b-5 {
    margin-bottom: 5px
}

html body .m-b-10 {
    margin-bottom: 10px
}

html body .m-b-15 {
    margin-bottom: 15px
}

html body .m-b-20 {
    margin-bottom: 20px
}

html body .m-b-30 {
    margin-bottom: 30px
}

html body .m-b-40 {
    margin-bottom: 40px
}

html body .vt {
    vertical-align: top
}

html body .vm {
    vertical-align: middle
}

html body .vb {
    vertical-align: bottom
}

.op-5 {
    opacity: 0.5
}

.op-3 {
    opacity: 0.3
}

html body .font-bold {
    font-weight: 700
}

html body .font-normal {
    font-weight: normal
}

html body .font-light {
    font-weight: 300
}

html body .font-medium {
    font-weight: 500
}

html body .font-16 {
    font-size: 16px
}

html body .font-14 {
    font-size: 14px
}

html body .font-10 {
    font-size: 10px
}

html body .font-18 {
    font-size: 18px
}

html body .font-20 {
    font-size: 20px
}

html body .b-0 {
    border: none !important
}

html body .b-r {
    border-right: 1px solid rgba(120, 130, 140, 0.13)
}

html body .b-l {
    border-left: 1px solid rgba(120, 130, 140, 0.13)
}

html body .b-b {
    border-bottom: 1px solid rgba(120, 130, 140, 0.13)
}

html body .b-t {
    border-top: 1px solid rgba(120, 130, 140, 0.13)
}

html body .b-all {
    border: 1px solid rgba(120, 130, 140, 0.13) !important
}

.thumb-sm {
    height: 32px;
    width: 32px
}

.thumb-md {
    height: 48px;
    width: 48px
}

.thumb-lg {
    height: 88px;
    width: 88px
}

.hide {
    display: none
}

.img-circle {
    border-radius: 100%
}

.radius {
    border-radius: 4px
}

.text-white {
    color: #ffffff !important
}

.text-danger {
    color: #ef5350 !important
}

.text-muted {
    color: #99abb4 !important
}

.text-warning {
    color: #ffb22b !important
}

.text-success {
    color: #06d79c !important
}

.text-info {
    color: #398bf7 !important
}

.text-inverse {
    color: #2f3d4a !important
}

html body .text-blue {
    color: #02bec9
}

html body .text-purple {
    color: #7460ee
}

html body .text-primary {
    color: #745af2 !important
}

html body .text-megna {
    color: #56c0d8
}

html body .text-dark {
    color: #67757c
}

html body .text-themecolor {
    color: #398bf7
}

.bg-primary {
    background-color: #745af2 !important
}

.bg-success {
    background-color: #06d79c !important
}

.bg-info {
    background-color: #398bf7 !important
}

.bg-warning {
    background-color: #ffb22b !important
}

.bg-danger {
    background-color: #ef5350 !important
}

html body .bg-megna {
    background-color: #56c0d8
}

html body .bg-theme {
    background-color: #398bf7
}

html body .bg-inverse {
    background-color: #2f3d4a
}

html body .bg-purple {
    background-color: #7460ee
}

html body .bg-light-part {
    background-color: rgba(0, 0, 0, 0.02)
}

html body .bg-light-primary {
    background-color: #f1effd
}

html body .bg-light-success {
    background-color: #e8fdeb
}

html body .bg-light-info {
    background-color: #cfecfe
}

html body .bg-light-extra {
    background-color: #ebf3f5
}

html body .bg-light-warning {
    background-color: #fff8ec
}

html body .bg-light-danger {
    background-color: #f9e7eb
}

html body .bg-light-inverse {
    background-color: #f6f6f6
}

html body .bg-light {
    background-color: #e9edf2
}

html body .bg-white {
    background-color: #ffffff
}

.round {
    line-height: 48px;
    color: #ffffff;
    width: 50px;
    height: 50px;
    display: inline-block;
    font-weight: 400;
    text-align: center;
    border-radius: 100%;
    background: #398bf7
}

.round img {
    border-radius: 100%
}

.round-lg {
    line-height: 65px;
    width: 60px;
    height: 60px;
    font-size: 30px
}

.round.round-info {
    background: #398bf7
}

.round.round-warning {
    background: #ffb22b
}

.round.round-danger {
    background: #ef5350
}

.round.round-success {
    background: #06d79c
}

.round.round-primary {
    background: #745af2
}

.label {
    padding: 3px 10px;
    line-height: 13px;
    color: #ffffff;
    font-weight: 400;
    border-radius: 4px;
    font-size: 75%
}

.label-rounded {
    border-radius: 60px
}

.label-custom {
    background-color: #56c0d8
}

.label-success {
    background-color: #06d79c
}

.label-info {
    background-color: #398bf7
}

.label-warning {
    background-color: #ffb22b
}

.label-danger {
    background-color: #ef5350
}

.label-megna {
    background-color: #56c0d8
}

.label-primary {
    background-color: #745af2
}

.label-purple {
    background-color: #7460ee
}

.label-red {
    background-color: #fb3a3a
}

.label-inverse {
    background-color: #2f3d4a
}

.label-default {
    background-color: #e9edf2
}

.label-white {
    background-color: #ffffff
}

.label-light-success {
    background-color: #e8fdeb;
    color: #06d79c
}

.label-light-info {
    background-color: #cfecfe;
    color: #398bf7
}

.label-light-warning {
    background-color: #fff8ec;
    color: #ffb22b
}

.label-light-danger {
    background-color: #f9e7eb;
    color: #ef5350
}

.label-light-megna {
    background-color: #e0f2f4;
    color: #56c0d8
}

.label-light-primary {
    background-color: #f1effd;
    color: #745af2
}

.label-light-inverse {
    background-color: #f6f6f6;
    color: #2f3d4a
}

.badge {
    font-weight: 400
}

.badge-xs {
    font-size: 9px
}

.badge-sm,
.badge-xs {
    -webkit-transform: translate(0, -2px);
    -ms-transform: translate(0, -2px);
    -o-transform: translate(0, -2px);
    transform: translate(0, -2px)
}

.badge-success {
    background-color: #06d79c
}

.badge-info {
    background-color: #398bf7
}

.badge-primary {
    background-color: #745af2
}

.badge-warning {
    background-color: #ffb22b;
    color: #ffffff
}

.badge-danger {
    background-color: #ef5350
}

.badge-purple {
    background-color: #7460ee
}

.badge-red {
    background-color: #fb3a3a
}

.badge-inverse {
    background-color: #2f3d4a
}

ul.list-style-none {
    margin: 0px;
    padding: 0px
}

ul.list-style-none li {
    list-style: none
}

ul.list-style-none li a {
    color: #67757c;
    padding: 8px 0px;
    display: block;
    text-decoration: none
}

ul.list-style-none li a:hover {
    color: #398bf7
}

.dropdown-item {
    padding: 8px 1rem;
    color: #67757c
}

.card-no-border .card {
    border: 0px;
    border-radius: 0px;
    -webkit-box-shadow: none;
    box-shadow: none
}

.card-no-border .shadow-none {
    -webkit-box-shadow: none;
    box-shadow: none
}

.card-outline-danger,
.card-outline-info,
.card-outline-primary,
.card-outline-success,
.card-outline-warning {
    background: #ffffff
}

.card-fullscreen {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    z-index: 9999;
    overflow: auto
}

.single-column .left-sidebar {
    display: none
}

.single-column .page-wrapper {
    margin-left: 0px
}

.fix-width {
    width: 100%;
    max-width: 1170px;
    margin: 0 auto
}

.left-sidebar {
    position: relative;
    width: 240px;
    height: 100%;
    top: 0px;
    z-index: 20;
    /*background: #fff;*/
    -webkit-box-shadow: 1px 0px 20px rgba(0, 0, 0, 0.08);
    box-shadow: 1px 0px 20px rgba(0, 0, 0, 0.08)
}

.fix-sidebar .left-sidebar {
    /*position: fixed*/
}

.fix-header.fix-sidebar .left-sidebar,
.fix-header .left-sidebar {
    padding-top: 70px
}


.scroll-sidebar {
    height: calc(100% - 30px)
}

.scroll-sidebar.ps .ps__scrollbar-y-rail {
    left: 2px;
    right: auto;
    background: none;
    width: 6px
}

.collapse.in {
    display: block
}

#main-wrapper {
    width: 100%;
    overflow: hidden
}

.boxed #main-wrapper {
    width: 100%;
    max-width: 1300px;
    margin: 0 auto;
    -webkit-box-shadow: 0 0 60px rgba(0, 0, 0, 0.1);
    box-shadow: 0 0 60px rgba(0, 0, 0, 0.1)
}


.boxed #main-wrapper .topbar {
    max-width: 1300px
}

.boxed #main-wrapper .footer {
    display: none
}

.page-wrapper {
    background: #f4f6f9;
    padding-bottom: 60px;
    position: relative;
    padding-top: 80px
}

.container-fluid {
    padding: 25px 15px;
    max-width: 1280px;
    margin: 0 auto;
    position: relative
}

.card {
    margin-bottom: 30px
}

.card .card-subtitle {
    font-weight: 300;
    margin-bottom: 15px;
    color: #99abb4
}

.card .card-title {
    position: relative
}

.fix-header .topbar {
    position: fixed;
    width: 100%;
    -webkit-box-shadow: 0 1px 5px rgba(0, 0, 0, 0.05);
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.05)
}

.fix-header .page-wrapper {
    padding-top: 0px
}

.fix-header.fix-sidebar .page-wrapper {
    padding-top: 125px
}

.fix-header .right-side-panel {
    top: 0;
    height: 100%
}

.topbar {
    position: relative;
    z-index: 50
}

.topbar .navbar-collapse {
    padding: 0 10px
}

.topbar .top-navbar {
    min-height: 70px;
    max-width: 1280px;
    margin: 0 auto;
    padding: 0px 0px 0px 16px
}

.topbar .top-navbar .dropdown-toggle:after {
    display: none
}

.topbar .top-navbar .navbar-header {
    line-height: 55px;
    padding-left: 10px
}

.topbar .top-navbar .navbar-header .navbar-brand {
    margin-right: 0px;
    padding-bottom: 0px;
    padding-top: 0px
}

.topbar .top-navbar .navbar-header .navbar-brand b {
    line-height: 70px;
    display: inline-block
}

.topbar .top-navbar .navbar-nav>.nav-item>.nav-link {
    padding-left: 15px;
    padding-right: 15px;
    font-size: 17px;
    line-height: 54px
}

.topbar .top-navbar .navbar-nav>.nav-item>span {
    line-height: 65px;
    font-size: 24px;
    font-weight: 500;
    color: #ffffff;
    padding: 0 10px
}

.topbar .top-navbar .navbar-nav>.nav-item.show {
    background: rgba(0, 0, 0, 0.05)
}

.topbar .profile-pic {
    width: 30px;
}

.topbar .dropdown-menu {
    box-shadow: 0 3px 12px rgba(0, 0, 0, 0.05);
    -webkit-box-shadow: 0 3px 12px rgba(0, 0, 0, 0.05);
    -moz-box-shadow: 0 3px 12px rgba(0, 0, 0, 0.05);
    border-color: rgba(120, 130, 140, 0.13)
}

.topbar .dropdown-menu .dropdown-item {
    padding: 7px 1.5rem
}





.logo-center .top-navbar .navbar-header {
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto
}

.page-titles {
    background: #f4f6f9;
    margin-bottom: 20px;
    padding: 0px
}

.page-titles h3 {
    margin-bottom: 0px;
    margin-top: 0px
}

.page-titles .breadcrumb {
    padding: 0px;
    margin-bottom: 0px;
    background: transparent;
    font-size: 14px
}

.page-titles .breadcrumb li {
    margin-top: 0px;
    margin-bottom: 0px
}

.page-titles .breadcrumb .breadcrumb-item+.breadcrumb-item:before {
    content: "\e649";
    font-family: themify;
    color: #a6b7bf;
    font-size: 11px
}

.page-titles .breadcrumb .breadcrumb-item.active {
    color: #99abb4
}

.p-relative {
    position: relative
}

.lstick {
    width: 2px;
    background: #398bf7;
    height: 30px;
    margin-left: -20px;
    margin-right: 18px;
    display: inline-block;
    vertical-align: middle
}



ul#themecolors {
    display: block
}

ul#themecolors li {
    display: inline-block
}

ul#themecolors li:first-child {
    display: block
}

ul#themecolors li a {
    width: 50px;
    height: 50px;
    display: inline-block;
    margin: 5px;
    color: transparent;
    position: relative
}

ul#themecolors li a.working:before {
    content: "\f00c";
    font-family: "FontAwesome";
    font-size: 18px;
    line-height: 50px;
    width: 50px;
    height: 50px;
    position: absolute;
    top: 0;
    left: 0;
    color: #ffffff;
    text-align: center
}

.default-theme {
    background: #99abb4
}

.green-theme {
    background: #06d79c
}

.yellow-theme {
    background: #ffb22b
}

.red-theme {
    background: #ef5350
}

.blue-theme {
    background: #398bf7
}

.purple-theme {
    background: #7460ee
}

.megna-theme {
    background: #56c0d8
}



.footer {
    bottom: 0;
    color: #67757c;
    left: 0px;
    padding: 17px 15px;
    position: absolute;
    right: 0;
    border-top: 1px solid rgba(120, 130, 140, 0.13);
    background: #ffffff;
    text-align: center
}

.slimScrollBar {
    z-index: 10 !important
}

.mega-dropdown {
    position: static;
    width: 100%
}

.mega-dropdown .dropdown-menu {
    width: 100%;
    padding: 30px;
    margin-top: 0px
}

.mega-dropdown ul {
    padding: 0px
}

.mega-dropdown ul li {
    list-style: none
}

.mega-dropdown .carousel-item .container {
    padding: 0px
}

.mega-dropdown .nav-accordion .card {
    margin-bottom: 1px
}

.mega-dropdown .nav-accordion .card-header {
    background: #ffffff
}

.mega-dropdown .nav-accordion .card-header h5 {
    margin: 0px
}

.mega-dropdown .nav-accordion .card-header h5 a {
    text-decoration: none;
    color: #67757c
}

ul.list-inline li {
    display: inline-block;
    padding: 0 8px
}

.vm.table td,
.vm.table th {
    vertical-align: middle
}

.no-th-brd.table th {
    border: 0px
}

.table.no-border tbody td {
    border: 0px
}

.table-responsive {
    display: block;
    width: 100%;
    overflow-x: auto;
    -ms-overflow-style: -ms-autohiding-scrollbar
}

.single-column .left-sidebar {
    display: none
}

.single-column .page-wrapper {
    margin-left: 0px !important;
    padding-top: 70px !important
}

.customtab li a.nav-link,
.profile-tab li a.nav-link {
    border: 0px;
    padding: 15px 20px;
    color: #67757c
}

.customtab li a.nav-link.active,
.profile-tab li a.nav-link.active {
    border-bottom: 2px solid #398bf7;
    color: #398bf7
}

.customtab li a.nav-link:hover,
.profile-tab li a.nav-link:hover {
    color: #398bf7
}

html body .flotTip,
html body .jqstooltip {
    width: auto !important;
    height: auto !important;
    background: #263238;
    color: #ffffff;
    padding: 5px 10px
}

body .jqstooltip {
    border-color: transparent;
    border-radius: 60px
}

.jq-icon-info {
    background-color: #398bf7;
    color: #ffffff
}

.jq-icon-success {
    background-color: #06d79c;
    color: #ffffff
}

.jq-icon-error {
    background-color: #ef5350;
    color: #ffffff
}

.jq-icon-warning {
    background-color: #ffb22b;
    color: #ffffff
}

.pagination>li:first-child>a,
.pagination>li:first-child>span {
    border-bottom-left-radius: 4px;
    border-top-left-radius: 4px
}

.pagination>li:last-child>a,
.pagination>li:last-child>span {
    border-bottom-right-radius: 4px;
    border-top-right-radius: 4px
}

.pagination>li>a,
.pagination>li>span {
    color: #263238
}

.pagination>li>a:focus,
.pagination>li>a:hover,
.pagination>li>span:focus,
.pagination>li>span:hover {
    background-color: #e9edf2
}

.pagination-split li {
    margin-left: 5px;
    display: inline-block;
    float: left
}

.pagination-split li:first-child {
    margin-left: 0
}

.pagination-split li a {
    -moz-border-radius: 4px;
    -webkit-border-radius: 4px;
    border-radius: 4px
}

.btn {
    padding: 7px 12px;
    cursor: pointer
}

.btn-group label {
    color: #ffffff !important;
    margin-bottom: 0px
}

.btn-group label.btn-secondary {
    color: #67757c !important
}

.btn-lg {
    padding: .75rem 1.5rem;
    font-size: 1.25rem
}

.btn-md {
    padding: 12px 55px;
    font-size: 16px
}

.btn-circle {
    border-radius: 100%;
    width: 40px;
    height: 40px;
    padding: 10px
}

.btn-circle.btn-sm {
    width: 35px;
    height: 35px;
    padding: 8px 10px;
    font-size: 14px
}

.btn-circle.btn-lg {
    width: 50px;
    height: 50px;
    padding: 14px 15px;
    font-size: 18px;
    line-height: 23px
}

.btn-circle.btn-xl {
    width: 70px;
    height: 70px;
    padding: 14px 15px;
    font-size: 24px
}

.btn-sm {
    padding: .25rem .5rem;
    font-size: 12px
}

.btn-xs {
    padding: .25rem .5rem;
    font-size: 10px
}

.button-list a,
.button-list button {
    margin: 5px 12px 5px 0
}

.btn-outline {
    color: inherit;
    background-color: transparent;
    -webkit-transition: all .5s;
    -o-transition: all .5s;
    transition: all .5s
}

.btn-rounded {
    border-radius: 60px;
    padding: 7px 18px
}

.btn-rounded.btn-lg {
    padding: .75rem 1.5rem
}

.btn-rounded.btn-sm {
    padding: .25rem .5rem;
    font-size: 12px
}

.btn-rounded.btn-xs {
    padding: .25rem .5rem;
    font-size: 10px
}

.btn-rounded.btn-md {
    padding: 12px 35px;
    font-size: 16px
}

.btn-secondary,
.btn-secondary.disabled {
    -webkit-box-shadow: 0 2px 2px 0 rgba(169, 169, 169, 0.14), 0 3px 1px -2px rgba(169, 169, 169, 0.2), 0 1px 5px 0 rgba(169, 169, 169, 0.12);
    box-shadow: 0 2px 2px 0 rgba(169, 169, 169, 0.14), 0 3px 1px -2px rgba(169, 169, 169, 0.2), 0 1px 5px 0 rgba(169, 169, 169, 0.12);
    -webkit-transition: 0.2s ease-in;
    -o-transition: 0.2s ease-in;
    transition: 0.2s ease-in;
    background-color: #ffffff;
    color: #67757c;
    border-color: #cccccc
}

.btn-secondary.disabled:hover,
.btn-secondary:hover {
    -webkit-box-shadow: 0 14px 26px -12px rgba(169, 169, 169, 0.42), 0 4px 23px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(169, 169, 169, 0.2);
    box-shadow: 0 14px 26px -12px rgba(169, 169, 169, 0.42), 0 4px 23px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(169, 169, 169, 0.2);
    color: #ffffff !important
}

.btn-secondary.active,
.btn-secondary.disabled.active,
.btn-secondary.disabled:active,
.btn-secondary.disabled:focus,
.btn-secondary:active,
.btn-secondary:focus {
    -webkit-box-shadow: 0 14px 26px -12px rgba(169, 169, 169, 0.42), 0 4px 23px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(169, 169, 169, 0.2);
    box-shadow: 0 14px 26px -12px rgba(169, 169, 169, 0.42), 0 4px 23px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(169, 169, 169, 0.2);
    color: #ffffff !important;
    background: #263238;
    border-color: #263238
}

.btn-secondary.disabled:active,
.btn-secondary.disabled:focus,
.btn-secondary.disabled:hover {
    color: #67757c !important;
    background-color: #ffffff;
    border-color: #cccccc;
    cursor: default
}

.btn-primary,
.btn-primary.disabled {
    background: #745af2;
    border: 1px solid #745af2;
    -webkit-box-shadow: 0 2px 2px 0 rgba(116, 96, 238, 0.14), 0 3px 1px -2px rgba(116, 96, 238, 0.2), 0 1px 5px 0 rgba(116, 96, 238, 0.12);
    box-shadow: 0 2px 2px 0 rgba(116, 96, 238, 0.14), 0 3px 1px -2px rgba(116, 96, 238, 0.2), 0 1px 5px 0 rgba(116, 96, 238, 0.12);
    -webkit-transition: 0.2s ease-in;
    -o-transition: 0.2s ease-in;
    transition: 0.2s ease-in
}

.btn-primary.disabled:hover,
.btn-primary:hover {
    background: #745af2;
    -webkit-box-shadow: 0 14px 26px -12px rgba(116, 96, 238, 0.42), 0 4px 23px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(116, 96, 238, 0.2);
    box-shadow: 0 14px 26px -12px rgba(116, 96, 238, 0.42), 0 4px 23px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(116, 96, 238, 0.2);
    border: 1px solid #745af2
}

.btn-primary.active,
.btn-primary.disabled.active,
.btn-primary.disabled:active,
.btn-primary.disabled:focus,
.btn-primary:active,
.btn-primary:focus {
    background: #6352ce;
    -webkit-box-shadow: 0 14px 26px -12px rgba(116, 96, 238, 0.42), 0 4px 23px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(116, 96, 238, 0.2);
    box-shadow: 0 14px 26px -12px rgba(116, 96, 238, 0.42), 0 4px 23px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(116, 96, 238, 0.2);
    border-color: transparent
}

.btn-themecolor,
.btn-themecolor.disabled {
    background: #398bf7;
    color: #ffffff;
    border: 1px solid #398bf7
}

.btn-themecolor.disabled:hover,
.btn-themecolor:hover {
    background: #398bf7;
    opacity: 0.7;
    border: 1px solid #398bf7
}

.btn-themecolor.active,
.btn-themecolor.disabled.active,
.btn-themecolor.disabled:active,
.btn-themecolor.disabled:focus,
.btn-themecolor:active,
.btn-themecolor:focus {
    background: #028ee1
}

.btn-success,
.btn-success.disabled {
    background: #06d79c;
    border: 1px solid #06d79c;
    -webkit-box-shadow: 0 2px 2px 0 rgba(40, 190, 189, 0.14), 0 3px 1px -2px rgba(40, 190, 189, 0.2), 0 1px 5px 0 rgba(40, 190, 189, 0.12);
    box-shadow: 0 2px 2px 0 rgba(40, 190, 189, 0.14), 0 3px 1px -2px rgba(40, 190, 189, 0.2), 0 1px 5px 0 rgba(40, 190, 189, 0.12);
    -webkit-transition: 0.2s ease-in;
    -o-transition: 0.2s ease-in;
    transition: 0.2s ease-in
}

.btn-success.disabled:hover,
.btn-success:hover {
    background: #06d79c;
    -webkit-box-shadow: 0 14px 26px -12px rgba(40, 190, 189, 0.42), 0 4px 23px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(40, 190, 189, 0.2);
    box-shadow: 0 14px 26px -12px rgba(40, 190, 189, 0.42), 0 4px 23px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(40, 190, 189, 0.2);
    border: 1px solid #06d79c
}

.btn-success.active,
.btn-success.disabled.active,
.btn-success.disabled:active,
.btn-success.disabled:focus,
.btn-success:active,
.btn-success:focus {
    background: #04b381;
    -webkit-box-shadow: 0 14px 26px -12px rgba(40, 190, 189, 0.42), 0 4px 23px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(40, 190, 189, 0.2);
    box-shadow: 0 14px 26px -12px rgba(40, 190, 189, 0.42), 0 4px 23px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(40, 190, 189, 0.2);
    border-color: transparent
}

.btn-info,
.btn-info.disabled {
    background: #398bf7;
    border: 1px solid #398bf7;
    -webkit-box-shadow: 0 2px 2px 0 rgba(66, 165, 245, 0.14), 0 3px 1px -2px rgba(66, 165, 245, 0.2), 0 1px 5px 0 rgba(66, 165, 245, 0.12);
    box-shadow: 0 2px 2px 0 rgba(66, 165, 245, 0.14), 0 3px 1px -2px rgba(66, 165, 245, 0.2), 0 1px 5px 0 rgba(66, 165, 245, 0.12);
    -webkit-transition: 0.2s ease-in;
    -o-transition: 0.2s ease-in;
    transition: 0.2s ease-in
}

.btn-info.disabled:hover,
.btn-info:hover {
    background: #398bf7;
    border: 1px solid #398bf7;
    -webkit-box-shadow: 0 14px 26px -12px rgba(23, 105, 255, 0.42), 0 4px 23px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(23, 105, 255, 0.2);
    box-shadow: 0 14px 26px -12px rgba(23, 105, 255, 0.42), 0 4px 23px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(23, 105, 255, 0.2)
}

.btn-info.active,
.btn-info.disabled.active,
.btn-info.disabled:active,
.btn-info.disabled:focus,
.btn-info:active,
.btn-info:focus {
    background: #028ee1;
    -webkit-box-shadow: 0 14px 26px -12px rgba(23, 105, 255, 0.42), 0 4px 23px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(23, 105, 255, 0.2);
    box-shadow: 0 14px 26px -12px rgba(23, 105, 255, 0.42), 0 4px 23px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(23, 105, 255, 0.2);
    border-color: transparent
}

.btn-warning,
.btn-warning.disabled {
    background: #ffb22b;
    color: #ffffff;
    -webkit-box-shadow: 0 2px 2px 0 rgba(248, 194, 0, 0.14), 0 3px 1px -2px rgba(248, 194, 0, 0.2), 0 1px 5px 0 rgba(248, 194, 0, 0.12);
    box-shadow: 0 2px 2px 0 rgba(248, 194, 0, 0.14), 0 3px 1px -2px rgba(248, 194, 0, 0.2), 0 1px 5px 0 rgba(248, 194, 0, 0.12);
    border: 1px solid #ffb22b;
    -webkit-transition: 0.2s ease-in;
    -o-transition: 0.2s ease-in;
    transition: 0.2s ease-in
}

.btn-warning.disabled:hover,
.btn-warning:hover {
    background: #ffb22b;
    color: #ffffff;
    -webkit-box-shadow: 0 14px 26px -12px rgba(248, 194, 0, 0.42), 0 4px 23px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(248, 194, 0, 0.2);
    box-shadow: 0 14px 26px -12px rgba(248, 194, 0, 0.42), 0 4px 23px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(248, 194, 0, 0.2);
    border: 1px solid #ffb22b
}

.btn-warning.active,
.btn-warning.disabled.active,
.btn-warning.disabled:active,
.btn-warning.disabled:focus,
.btn-warning:active,
.btn-warning:focus {
    background: #e9ab2e;
    color: #ffffff;
    -webkit-box-shadow: 0 14px 26px -12px rgba(248, 194, 0, 0.42), 0 4px 23px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(248, 194, 0, 0.2);
    box-shadow: 0 14px 26px -12px rgba(248, 194, 0, 0.42), 0 4px 23px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(248, 194, 0, 0.2);
    border-color: transparent
}

.btn-danger,
.btn-danger.disabled {
    background: #ef5350;
    border: 1px solid #ef5350;
    -webkit-box-shadow: 0 2px 2px 0 rgba(239, 83, 80, 0.14), 0 3px 1px -2px rgba(239, 83, 80, 0.2), 0 1px 5px 0 rgba(239, 83, 80, 0.12);
    box-shadow: 0 2px 2px 0 rgba(239, 83, 80, 0.14), 0 3px 1px -2px rgba(239, 83, 80, 0.2), 0 1px 5px 0 rgba(239, 83, 80, 0.12);
    -webkit-transition: 0.2s ease-in;
    -o-transition: 0.2s ease-in;
    transition: 0.2s ease-in
}

.btn-danger.disabled:hover,
.btn-danger:hover {
    background: #ef5350;
    -webkit-box-shadow: 0 14px 26px -12px rgba(239, 83, 80, 0.42), 0 4px 23px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(239, 83, 80, 0.2);
    box-shadow: 0 14px 26px -12px rgba(239, 83, 80, 0.42), 0 4px 23px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(239, 83, 80, 0.2);
    border: 1px solid #ef5350
}

.btn-danger.active,
.btn-danger.disabled.active,
.btn-danger.disabled:active,
.btn-danger.disabled:focus,
.btn-danger:active,
.btn-danger:focus {
    background: #e6294b;
    -webkit-box-shadow: 0 14px 26px -12px rgba(239, 83, 80, 0.42), 0 4px 23px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(239, 83, 80, 0.2);
    box-shadow: 0 14px 26px -12px rgba(239, 83, 80, 0.42), 0 4px 23px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(239, 83, 80, 0.2);
    border-color: transparent
}

.btn-inverse,
.btn-inverse.disabled {
    background: #2f3d4a;
    border: 1px solid #2f3d4a;
    color: #ffffff
}

.btn-inverse.disabled:hover,
.btn-inverse:hover {
    background: #2f3d4a;
    opacity: 0.7;
    color: #ffffff;
    border: 1px solid #2f3d4a
}

.btn-inverse.active,
.btn-inverse.disabled.active,
.btn-inverse.disabled:active,
.btn-inverse.disabled:focus,
.btn-inverse:active,
.btn-inverse:focus {
    background: #232a37;
    color: #ffffff
}

.btn-red,
.btn-red.disabled {
    background: #fb3a3a;
    border: 1px solid #fb3a3a;
    color: #ffffff
}

.btn-red.disabled:hover,
.btn-red:hover {
    opacity: 0.7;
    border: 1px solid #fb3a3a;
    background: #fb3a3a
}

.btn-red.active,
.btn-red.disabled.active,
.btn-red.disabled:active,
.btn-red.disabled:focus,
.btn-red:active,
.btn-red:focus {
    background: #e6294b
}

.btn-outline-secondary {
    background-color: #ffffff;
    -webkit-box-shadow: 0 2px 2px 0 rgba(169, 169, 169, 0.14), 0 3px 1px -2px rgba(169, 169, 169, 0.2), 0 1px 5px 0 rgba(169, 169, 169, 0.12);
    box-shadow: 0 2px 2px 0 rgba(169, 169, 169, 0.14), 0 3px 1px -2px rgba(169, 169, 169, 0.2), 0 1px 5px 0 rgba(169, 169, 169, 0.12);
    -webkit-transition: 0.2s ease-in;
    -o-transition: 0.2s ease-in;
    transition: 0.2s ease-in;
    border-color: #cccccc
}

.btn-outline-secondary.focus,
.btn-outline-secondary:focus,
.btn-outline-secondary:hover {
    -webkit-box-shadow: 0 14px 26px -12px rgba(169, 169, 169, 0.42), 0 4px 23px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(169, 169, 169, 0.2);
    box-shadow: 0 14px 26px -12px rgba(169, 169, 169, 0.42), 0 4px 23px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(169, 169, 169, 0.2)
}

.btn-outline-secondary.active,
.btn-outline-secondary:active,
.btn-outline-secondary:focus {
    -webkit-box-shadow: 0 14px 26px -12px rgba(169, 169, 169, 0.42), 0 4px 23px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(169, 169, 169, 0.2);
    box-shadow: 0 14px 26px -12px rgba(169, 169, 169, 0.42), 0 4px 23px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(169, 169, 169, 0.2)
}

.btn-outline-primary {
    color: #745af2;
    background-color: #ffffff;
    border-color: #745af2;
    -webkit-box-shadow: 0 2px 2px 0 rgba(116, 96, 238, 0.14), 0 3px 1px -2px rgba(116, 96, 238, 0.2), 0 1px 5px 0 rgba(116, 96, 238, 0.12);
    box-shadow: 0 2px 2px 0 rgba(116, 96, 238, 0.14), 0 3px 1px -2px rgba(116, 96, 238, 0.2), 0 1px 5px 0 rgba(116, 96, 238, 0.12);
    -webkit-transition: 0.2s ease-in;
    -o-transition: 0.2s ease-in;
    transition: 0.2s ease-in
}

.btn-outline-primary.focus,
.btn-outline-primary:focus,
.btn-outline-primary:hover {
    background: #745af2;
    -webkit-box-shadow: 0 14px 26px -12px rgba(116, 96, 238, 0.42), 0 4px 23px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(116, 96, 238, 0.2);
    box-shadow: 0 14px 26px -12px rgba(116, 96, 238, 0.42), 0 4px 23px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(116, 96, 238, 0.2);
    color: #ffffff;
    border-color: #745af2
}

.btn-outline-primary.active,
.btn-outline-primary:active,
.btn-outline-primary:focus {
    -webkit-box-shadow: 0 14px 26px -12px rgba(116, 96, 238, 0.42), 0 4px 23px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(116, 96, 238, 0.2);
    box-shadow: 0 14px 26px -12px rgba(116, 96, 238, 0.42), 0 4px 23px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(116, 96, 238, 0.2);
    background-color: #6352ce;
    border-color: #6352ce
}

.btn-outline-success {
    color: #06d79c;
    background-color: transparent;
    border-color: #06d79c;
    -webkit-box-shadow: 0 2px 2px 0 rgba(40, 190, 189, 0.14), 0 3px 1px -2px rgba(40, 190, 189, 0.2), 0 1px 5px 0 rgba(40, 190, 189, 0.12);
    box-shadow: 0 2px 2px 0 rgba(40, 190, 189, 0.14), 0 3px 1px -2px rgba(40, 190, 189, 0.2), 0 1px 5px 0 rgba(40, 190, 189, 0.12);
    -webkit-transition: 0.2s ease-in;
    -o-transition: 0.2s ease-in;
    transition: 0.2s ease-in
}

.btn-outline-success.focus,
.btn-outline-success:focus,
.btn-outline-success:hover {
    background: #06d79c;
    border-color: #06d79c;
    color: #ffffff;
    -webkit-box-shadow: 0 14px 26px -12px rgba(40, 190, 189, 0.42), 0 4px 23px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(40, 190, 189, 0.2);
    box-shadow: 0 14px 26px -12px rgba(40, 190, 189, 0.42), 0 4px 23px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(40, 190, 189, 0.2)
}

.btn-outline-success.active,
.btn-outline-success:active,
.btn-outline-success:focus {
    -webkit-box-shadow: 0 14px 26px -12px rgba(40, 190, 189, 0.42), 0 4px 23px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(40, 190, 189, 0.2);
    box-shadow: 0 14px 26px -12px rgba(40, 190, 189, 0.42), 0 4px 23px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(40, 190, 189, 0.2);
    background-color: #04b381;
    border-color: #04b381
}

.btn-outline-info {
    color: #398bf7;
    background-color: transparent;
    border-color: #398bf7;
    -webkit-box-shadow: 0 2px 2px 0 rgba(66, 165, 245, 0.14), 0 3px 1px -2px rgba(66, 165, 245, 0.2), 0 1px 5px 0 rgba(66, 165, 245, 0.12);
    box-shadow: 0 2px 2px 0 rgba(66, 165, 245, 0.14), 0 3px 1px -2px rgba(66, 165, 245, 0.2), 0 1px 5px 0 rgba(66, 165, 245, 0.12);
    -webkit-transition: 0.2s ease-in;
    -o-transition: 0.2s ease-in;
    transition: 0.2s ease-in
}

.btn-outline-info.focus,
.btn-outline-info:focus,
.btn-outline-info:hover {
    background: #398bf7;
    border-color: #398bf7;
    color: #ffffff;
    -webkit-box-shadow: 0 14px 26px -12px rgba(23, 105, 255, 0.42), 0 4px 23px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(23, 105, 255, 0.2);
    box-shadow: 0 14px 26px -12px rgba(23, 105, 255, 0.42), 0 4px 23px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(23, 105, 255, 0.2)
}

.btn-outline-info.active,
.btn-outline-info:active,
.btn-outline-info:focus {
    -webkit-box-shadow: 0 14px 26px -12px rgba(23, 105, 255, 0.42), 0 4px 23px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(23, 105, 255, 0.2);
    box-shadow: 0 14px 26px -12px rgba(23, 105, 255, 0.42), 0 4px 23px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(23, 105, 255, 0.2);
    background-color: #028ee1;
    border-color: #028ee1
}

.btn-outline-warning {
    color: #ffb22b;
    background-color: transparent;
    border-color: #ffb22b;
    -webkit-box-shadow: 0 2px 2px 0 rgba(248, 194, 0, 0.14), 0 3px 1px -2px rgba(248, 194, 0, 0.2), 0 1px 5px 0 rgba(248, 194, 0, 0.12);
    box-shadow: 0 2px 2px 0 rgba(248, 194, 0, 0.14), 0 3px 1px -2px rgba(248, 194, 0, 0.2), 0 1px 5px 0 rgba(248, 194, 0, 0.12);
    -webkit-transition: 0.2s ease-in;
    -o-transition: 0.2s ease-in;
    transition: 0.2s ease-in
}

.btn-outline-warning.focus,
.btn-outline-warning:focus,
.btn-outline-warning:hover {
    background: #ffb22b;
    border-color: #ffb22b;
    color: #ffffff;
    -webkit-box-shadow: 0 14px 26px -12px rgba(248, 194, 0, 0.42), 0 4px 23px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(248, 194, 0, 0.2);
    box-shadow: 0 14px 26px -12px rgba(248, 194, 0, 0.42), 0 4px 23px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(248, 194, 0, 0.2)
}

.btn-outline-warning.active,
.btn-outline-warning:active,
.btn-outline-warning:focus {
    -webkit-box-shadow: 0 14px 26px -12px rgba(248, 194, 0, 0.42), 0 4px 23px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(248, 194, 0, 0.2);
    box-shadow: 0 14px 26px -12px rgba(248, 194, 0, 0.42), 0 4px 23px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(248, 194, 0, 0.2);
    background-color: #e9ab2e;
    border-color: #e9ab2e
}

.btn-outline-danger {
    color: #ef5350;
    background-color: transparent;
    border-color: #ef5350;
    -webkit-box-shadow: 0 2px 2px 0 rgba(239, 83, 80, 0.14), 0 3px 1px -2px rgba(239, 83, 80, 0.2), 0 1px 5px 0 rgba(239, 83, 80, 0.12);
    box-shadow: 0 2px 2px 0 rgba(239, 83, 80, 0.14), 0 3px 1px -2px rgba(239, 83, 80, 0.2), 0 1px 5px 0 rgba(239, 83, 80, 0.12);
    -webkit-transition: 0.2s ease-in;
    -o-transition: 0.2s ease-in;
    transition: 0.2s ease-in
}

.btn-outline-danger.focus,
.btn-outline-danger:focus,
.btn-outline-danger:hover {
    background: #ef5350;
    border-color: #ef5350;
    color: #ffffff;
    -webkit-box-shadow: 0 14px 26px -12px rgba(239, 83, 80, 0.42), 0 4px 23px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(239, 83, 80, 0.2);
    box-shadow: 0 14px 26px -12px rgba(239, 83, 80, 0.42), 0 4px 23px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(239, 83, 80, 0.2)
}

.btn-outline-danger.active,
.btn-outline-danger:active,
.btn-outline-danger:focus {
    -webkit-box-shadow: 0 14px 26px -12px rgba(239, 83, 80, 0.42), 0 4px 23px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(239, 83, 80, 0.2);
    box-shadow: 0 14px 26px -12px rgba(239, 83, 80, 0.42), 0 4px 23px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(239, 83, 80, 0.2);
    background-color: #e6294b
}

.btn-outline-red {
    color: #fb3a3a;
    background-color: transparent;
    border-color: #fb3a3a
}

.btn-outline-red.focus,
.btn-outline-red:focus,
.btn-outline-red:hover {
    background: #fb3a3a;
    border-color: #fb3a3a;
    color: #ffffff;
    -webkit-box-shadow: 0 14px 26px -12px rgba(239, 83, 80, 0.42), 0 4px 23px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(239, 83, 80, 0.2);
    box-shadow: 0 14px 26px -12px rgba(239, 83, 80, 0.42), 0 4px 23px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(239, 83, 80, 0.2)
}

.btn-outline-red.active,
.btn-outline-red:active,
.btn-outline-red:focus {
    -webkit-box-shadow: 0 14px 26px -12px rgba(239, 83, 80, 0.42), 0 4px 23px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(239, 83, 80, 0.2);
    box-shadow: 0 14px 26px -12px rgba(239, 83, 80, 0.42), 0 4px 23px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(239, 83, 80, 0.2);
    background-color: #d61f1f
}

.btn-outline-inverse {
    color: #2f3d4a;
    background-color: transparent;
    border-color: #2f3d4a
}

.btn-outline-inverse.focus,
.btn-outline-inverse:focus,
.btn-outline-inverse:hover {
    background: #2f3d4a;
    border-color: #2f3d4a;
    color: #ffffff
}

.btn-primary.active.focus,
.btn-primary.active:focus,
.btn-primary.active:hover,
.btn-primary.focus,
.btn-primary.focus:active,
.btn-primary:active:focus,
.btn-primary:active:hover,
.btn-primary:focus,
.open>.dropdown-toggle.btn-primary.focus,
.open>.dropdown-toggle.btn-primary:focus,
.open>.dropdown-toggle.btn-primary:hover,
.show>.btn-primary.dropdown-toggle {
    background-color: #6352ce;
    border: 1px solid #6352ce
}

.btn-success.active.focus,
.btn-success.active:focus,
.btn-success.active:hover,
.btn-success.focus,
.btn-success.focus:active,
.btn-success:active:focus,
.btn-success:active:hover,
.btn-success:focus,
.open>.dropdown-toggle.btn-success.focus,
.open>.dropdown-toggle.btn-success:focus,
.open>.dropdown-toggle.btn-success:hover,
.show>.btn-success.dropdown-toggle {
    background-color: #04b381;
    border: 1px solid #04b381
}

.btn-info.active.focus,
.btn-info.active:focus,
.btn-info.active:hover,
.btn-info.focus,
.btn-info.focus:active,
.btn-info:active:focus,
.btn-info:active:hover,
.btn-info:focus,
.open>.dropdown-toggle.btn-info.focus,
.open>.dropdown-toggle.btn-info:focus,
.open>.dropdown-toggle.btn-info:hover,
.show>.btn-info.dropdown-toggle {
    background-color: #028ee1;
    border: 1px solid #028ee1
}

.btn-warning.active.focus,
.btn-warning.active:focus,
.btn-warning.active:hover,
.btn-warning.focus,
.btn-warning.focus:active,
.btn-warning:active:focus,
.btn-warning:active:hover,
.btn-warning:focus,
.open>.dropdown-toggle.btn-warning.focus,
.open>.dropdown-toggle.btn-warning:focus,
.open>.dropdown-toggle.btn-warning:hover,
.show>.btn-warning.dropdown-toggle {
    background-color: #e9ab2e;
    border: 1px solid #e9ab2e
}

.btn-danger.active.focus,
.btn-danger.active:focus,
.btn-danger.active:hover,
.btn-danger.focus,
.btn-danger.focus:active,
.btn-danger:active:focus,
.btn-danger:active:hover,
.btn-danger:focus,
.open>.dropdown-toggle.btn-danger.focus,
.open>.dropdown-toggle.btn-danger:focus,
.open>.dropdown-toggle.btn-danger:hover,
.show>.btn-danger.dropdown-toggle {
    background-color: #e6294b;
    border: 1px solid #e6294b
}

.btn-inverse.active,
.btn-inverse.focus,
.btn-inverse:active,
.btn-inverse:focus,
.btn-inverse:hover,
.btn-inverse:hover,
.open>.dropdown-toggle.btn-inverse {
    background-color: #232a37;
    border: 1px solid #232a37
}

.btn-secondary.active.focus,
.btn-secondary.active:focus,
.btn-secondary.active:hover,
.btn-secondary.focus,
.btn-secondary.focus:active,
.btn-secondary:active:focus,
.btn-secondary:active:hover,
.btn-secondary:focus,
.open>.dropdown-toggle.btn-secondary.focus,
.open>.dropdown-toggle.btn-secondary:focus,
.open>.dropdown-toggle.btn-secondary:hover,
.show>.btn-secondary.dropdown-toggle {
    color: #ffffff
}

.btn-red.active,
.btn-red.focus,
.btn-red:active,
.btn-red:focus,
.btn-red:hover,
.btn-red:hover,
.open>.dropdown-toggle.btn-red {
    background-color: #d61f1f;
    border: 1px solid #d61f1f;
    color: #ffffff
}

.button-box .btn {
    margin: 0 8px 8px 0px
}

.btn-label {
    background: rgba(0, 0, 0, 0.05);
    display: inline-block;
    margin: -6px 12px -6px -14px;
    padding: 7px 15px
}

.btn-facebook {
    color: #ffffff;
    background-color: #3b5998
}

.btn-facebook:hover {
    color: #ffffff
}

.btn-twitter {
    color: #ffffff;
    background-color: #55acee
}

.btn-twitter:hover {
    color: #ffffff
}

.btn-linkedin {
    color: #ffffff;
    background-color: #007bb6
}

.btn-linkedin:hover {
    color: #ffffff
}

.btn-dribbble {
    color: #ffffff;
    background-color: #ea4c89
}

.btn-dribbble:hover {
    color: #ffffff
}

.btn-googleplus {
    color: #ffffff;
    background-color: #dd4b39
}

.btn-googleplus:hover {
    color: #ffffff
}

.btn-instagram {
    color: #ffffff;
    background-color: #3f729b
}

.btn-pinterest {
    color: #ffffff;
    background-color: #cb2027
}

.btn-dropbox {
    color: #ffffff;
    background-color: #007ee5
}

.btn-flickr {
    color: #ffffff;
    background-color: #ff0084
}

.btn-tumblr {
    color: #ffffff;
    background-color: #32506d
}

.btn-skype {
    color: #ffffff;
    background-color: #00aff0
}

.btn-youtube {
    color: #ffffff;
    background-color: #bb0000
}

.btn-github {
    color: #ffffff;
    background-color: #171515
}

.button-group .btn {
    margin-bottom: 5px;
    margin-right: 5px
}

.no-button-group .btn {
    margin-bottom: 5px;
    margin-right: 0px
}

.btn .text-active {
    display: none
}

.btn.active .text-active {
    display: inline-block
}

.btn.active .text {
    display: none
}

.custom-select {
    background: url(https://r2.comparebench.com/assets/template/images/custom-select.png) right 0.75rem center no-repeat
}

textarea {
    resize: none
}

.form-control {
    color: #67757c;
    min-height: 38px;
    display: initial
}

.form-control-sm {
    min-height: 20px
}

.form-control:disabled,
.form-control[readonly] {
    opacity: 0.7
}

.custom-control-input:focus~.custom-control-indicator {
    -webkit-box-shadow: none;
    box-shadow: none
}

.custom-control-input:checked~.custom-control-indicator {
    background-color: #06d79c
}

form label {
    font-weight: 400
}

.form-group {
    margin-bottom: 25px
}

.form-horizontal label {
    margin-bottom: 0px
}

.form-control-static {
    padding-top: 0px
}

.form-bordered .form-group {
    border-bottom: 1px solid rgba(120, 130, 140, 0.13);
    padding-bottom: 20px
}

.dropzone {
    border: 1px dashed #b1b8bb
}

.dropzone .dz-message {
    padding: 5% 0;
    margin: 0px
}

.asColorPicker-dropdown {
    max-width: 260px
}

.asColorPicker-trigger {
    position: absolute;
    top: 0;
    right: -35px;
    height: 38px;
    width: 37px;
    border: 0
}

.asColorPicker-clear {
    display: none;
    position: absolute;
    top: 5px;
    right: 10px;
    text-decoration: none
}

table th {
    font-weight: 400
}

.label {
    display: inline-block
}

.input-form .btn {
    padding: 8px 12px
}

.form-material .form-group {
    overflow: hidden
}

.form-material .form-control {
    background-color: transparent;
    background-position: center bottom, center calc(100% - 1px);
    background-repeat: no-repeat;
    background-size: 0 2px, 100% 1px;
    padding: 0;
    -webkit-transition: background 0s ease-out 0s;
    -o-transition: background 0s ease-out 0s;
    transition: background 0s ease-out 0s
}

.form-material .form-control,
.form-material .form-control.focus,
.form-material .form-control:focus {
    background-image: -webkit-gradient(linear, left top, left bottom, from(#398bf7), to(#398bf7)), -webkit-gradient(linear, left top, left bottom, from(#e9edf2), to(#e9edf2));
    background-image: -webkit-linear-gradient(#398bf7, #398bf7), -webkit-linear-gradient(#e9edf2, #e9edf2);
    background-image: -o-linear-gradient(#398bf7, #398bf7), -o-linear-gradient(#e9edf2, #e9edf2);
    background-image: linear-gradient(#398bf7, #398bf7), linear-gradient(#e9edf2, #e9edf2);
    border: 0 none;
    border-radius: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
    float: none
}

.form-material .form-control.focus,
.form-material .form-control:focus {
    background-size: 100% 2px, 100% 1px;
    outline: 0 none;
    -webkit-transition-duration: 0.3s;
    -o-transition-duration: 0.3s;
    transition-duration: 0.3s
}

.form-control-line .form-group {
    overflow: hidden
}

.form-control-line .form-control {
    border: 0px;
    border-radius: 0px;
    padding-left: 0px;
    border-bottom: 1px solid #f6f9ff
}

.form-control-line .form-control:focus {
    border-bottom: 1px solid #398bf7
}

.error .help-block {
    color: #fb3a3a
}



form p {
    margin-bottom: 10px;
    text-align: left
}

form p:last-child {
    margin-bottom: 0
}



.card {
    -webkit-box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1);
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1);
    border-radius: 4px
}

.well,
pre {
    -webkit-box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1);
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1)
}

.page-titles .justify-content-end:last-child .d-flex {
    margin-right: 10px
}

.btn-circle.right-side-toggle {
    position: fixed;
    bottom: 20px;
    right: 20px;
    padding: 25px;
    z-index: 10
}


.error .form-control {
    border-color: #ef5350
}

.validate .form-control {
    border-color: #06d79c
}

.table-box {
    display: table;
    width: 100%
}

.table.no-border tbody td {
    border: 0px
}

.table td,
.table th {
    border-color: #f3f1f1
}

.table th,
.table thead th {
    font-weight: 500
}

.table-hover tbody tr:hover {
    background: #e9edf2
}
.table th,
.table thead th {
    border: 0px
}


.topbar .top-navbar .mailbox {
    width: 300px
}

.topbar .top-navbar .mailbox ul {
    padding: 0px
}

.topbar .top-navbar .mailbox ul li {
    list-style: none
}

@media (min-width:1600px) {
    .col-xlg-1,
    .col-xlg-2,
    .col-xlg-3,
    .col-xlg-4,
    .col-xlg-5,
    .col-xlg-6,
    .col-xlg-7,
    .col-xlg-8,
    .col-xlg-9,
    .col-xlg-10,
    .col-xlg-11,
    .col-xlg-12 {
        float: left
    }
    .col-xlg-12 {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 100%;
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%
    }
    .col-xlg-11 {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 91.66666667%;
        -ms-flex: 0 0 91.66666667%;
        flex: 0 0 91.66666667%;
        max-width: 91.66666667%
    }
    .col-xlg-10 {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 83.33333333%;
        -ms-flex: 0 0 83.33333333%;
        flex: 0 0 83.33333333%;
        max-width: 83.33333333%
    }
    .col-xlg-9 {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 75%;
        -ms-flex: 0 0 75%;
        flex: 0 0 75%;
        max-width: 75%
    }
    .col-xlg-8 {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 66.66666667%;
        -ms-flex: 0 0 66.66666667%;
        flex: 0 0 66.66666667%;
        max-width: 66.66666667%
    }
    .col-xlg-7 {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 58.33333333%;
        -ms-flex: 0 0 58.33333333%;
        flex: 0 0 58.33333333%;
        max-width: 58.33333333%
    }
    .col-xlg-6 {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 50%;
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%
    }
    .col-xlg-5 {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 41.66666667%;
        -ms-flex: 0 0 41.66666667%;
        flex: 0 0 41.66666667%;
        max-width: 41.66666667%
    }
    .col-xlg-4 {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 33.33333333%;
        -ms-flex: 0 0 33.33333333%;
        flex: 0 0 33.33333333%;
        max-width: 33.33333333%
    }
    .col-xlg-3 {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 25%;
        -ms-flex: 0 0 25%;
        flex: 0 0 25%;
        max-width: 25%
    }
    .col-xlg-2 {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 16.66666667%;
        -ms-flex: 0 0 16.66666667%;
        flex: 0 0 16.66666667%;
        max-width: 16.66666667%
    }
    .col-xlg-1 {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 8.33333333%;
        -ms-flex: 0 0 8.33333333%;
        flex: 0 0 8.33333333%;
        max-width: 8.33333333%
    }
    .col-xlg-pull-12 {
        right: 100%
    }
    .col-xlg-pull-11 {
        right: 91.66666667%
    }
    .col-xlg-pull-10 {
        right: 83.33333333%
    }
    .col-xlg-pull-9 {
        right: 75%
    }
    .col-xlg-pull-8 {
        right: 66.66666667%
    }
    .col-xlg-pull-7 {
        right: 58.33333333%
    }
    .col-xlg-pull-6 {
        right: 50%
    }
    .col-xlg-pull-5 {
        right: 41.66666667%
    }
    .col-xlg-pull-4 {
        right: 33.33333333%
    }
    .col-xlg-pull-3 {
        right: 25%
    }
    .col-xlg-pull-2 {
        right: 16.66666667%
    }
    .col-xlg-pull-1 {
        right: 8.33333333%
    }
    .col-xlg-pull-0 {
        right: auto
    }
    .col-xlg-push-12 {
        left: 100%
    }
    .col-xlg-push-11 {
        left: 91.66666667%
    }
    .col-xlg-push-10 {
        left: 83.33333333%
    }
    .col-xlg-push-9 {
        left: 75%
    }
    .col-xlg-push-8 {
        left: 66.66666667%
    }
    .col-xlg-push-7 {
        left: 58.33333333%
    }
    .col-xlg-push-6 {
        left: 50%
    }
    .col-xlg-push-5 {
        left: 41.66666667%
    }
    .col-xlg-push-4 {
        left: 33.33333333%
    }
    .col-xlg-push-3 {
        left: 25%
    }
    .col-xlg-push-2 {
        left: 16.66666667%
    }
    .col-xlg-push-1 {
        left: 8.33333333%
    }
    .col-xlg-push-0 {
        left: auto
    }
    .offset-xlg-12 {
        margin-left: 100%
    }
    .offset-xlg-11 {
        margin-left: 91.66666667%
    }
    .offset-xlg-10 {
        margin-left: 83.33333333%
    }
    .offset-xlg-9 {
        margin-left: 75%
    }
    .offset-xlg-8 {
        margin-left: 66.66666667%
    }
    .offset-xlg-7 {
        margin-left: 58.33333333%
    }
    .offset-xlg-6 {
        margin-left: 50%
    }
    .offset-xlg-5 {
        margin-left: 41.66666667%
    }
    .offset-xlg-4 {
        margin-left: 33.33333333%
    }
    .offset-xlg-3 {
        margin-left: 25%
    }
    .offset-xlg-2 {
        margin-left: 16.66666667%
    }
    .offset-xlg-1 {
        margin-left: 8.33333333%
    }
    .offset-xlg-0 {
        margin-left: 0
    }
}

.col-xlg-1,
.col-xlg-2,
.col-xlg-3,
.col-xlg-4,
.col-xlg-5,
.col-xlg-6,
.col-xlg-7,
.col-xlg-8,
.col-xlg-9,
.col-xlg-10,
.col-xlg-11,
.col-xlg-12 {
    position: relative;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px
}


.invisible {
    visibility: hidden !important
}

.hidden-xs-up {
    display: none !important
}

@media (max-width:575px) {
    .hidden-xs-down {
        display: none !important
    }
}

@media (min-width:576px) {
    .hidden-sm-up {
        display: none !important
    }
}

@media (max-width:767px) {
    .hidden-sm-down {
        display: none !important
    }
}

@media (min-width:768px) {
    .hidden-md-up {
        display: none !important
    }
}

@media (max-width:991px) {
    .hidden-md-down {
        display: none !important
    }
}

@media (min-width:992px) {
    .hidden-lg-up {
        display: none !important
    }
}

@media (max-width:1199px) {
    .hidden-lg-down {
        display: none !important
    }
}

@media (min-width:1200px) {
    .hidden-xl-up {
        display: none !important
    }
}

.hidden-xl-down {
    display: none !important
}

.card-inverse .card-blockquote,
.card-inverse .card-footer,
.card-inverse .card-header,
.card-inverse .card-title {
    color: #ffffff
}

@media (min-width:768px) {
    .navbar-header {
        width: 240px;
        -webkit-flex-shrink: 0;
        -ms-flex-negative: 0;
        flex-shrink: 0
    }
    .navbar-header .navbar-brand {
        padding-top: 0px
    }
    .page-titles .breadcrumb {
        float: right
    }
    .material-icon-list-demo .icons div {
        width: 33%;
        padding: 15px;
        display: inline-block;
        line-height: 40px
    }
}

@media (max-width:767px) {
    .container-fluid {
        padding: 25px 15px 25px 15px
    }
    .fix-header.fix-sidebar .page-wrapper {
        padding-top: 70px
    }
    .topbar {
        position: fixed;
        width: 100%
    }
    .topbar .top-navbar {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -webkit-flex-direction: row;
        -ms-flex-direction: row;
        flex-direction: row;
        -webkit-flex-wrap: nowrap;
        -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
        -webkit-align-items: center;
        padding: 0px
    }
    .topbar .top-navbar .navbar-collapse {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        width: 100%
    }
    .topbar .top-navbar .navbar-nav {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -webkit-flex-direction: row;
        -ms-flex-direction: row;
        flex-direction: row
    }
    .topbar .top-navbar .navbar-nav>.nav-item.show {
        position: static
    }
    .topbar .top-navbar .navbar-nav>.nav-item.show .dropdown-menu {
        width: 100%;
        margin-top: 0px
    }
    .topbar .top-navbar .navbar-nav>.nav-item>.nav-link {
        padding-left: .50rem;
        padding-right: .50rem
    }
    .topbar .top-navbar .navbar-nav .dropdown-menu {
        position: absolute
    }
    .mega-dropdown .dropdown-menu {
        height: 480px;
        overflow: auto
    }
    .mini-sidebar .page-wrapper {
        margin-left: 0px;
        padding-top: 70px
    }

    .material-icon-list-demo .icons div {
        width: 100%
    }
}
