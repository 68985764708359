a {
    color: #009efb;
}
button {
    outline: none !important;
}
mat-dialog-container{
    background-color: #292a2d !important;
    color: #babcbd !important
}
mat-toolbar{
    background-color: #202124 !important;
}

.compare-bar {
  position: fixed;
  bottom: -150px;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.25);
  height: 150px;
  display: flex;
  z-index: 1;
}
.compare-bar-toggle {
  float: left;
  position: absolute;
  bottom: 150px;
  cursor: pointer;
  background-color: #117a8b;
  padding: 5px 5px 5px 5px;
  border-radius: 0px;
  margin-left: 5px;
  display: block;
}


@keyframes overlayMoveUp {
  from {
    bottom: -150px;
  }
  to {
    bottom: 0px;
  }
}

@keyframes overlayMoveDown {
  from {
    bottom: 0px;
  }
  to {
    bottom: -75px;
  }
}
.roadmap-container {
  margin: 0 auto;
  padding-bottom: 50px;
  width: 100%;
}

.roadmap-panel {
  border-radius: 10px;
  padding: 15px 25px;
  position: relative;
  width: 100%;
  z-index: 10;
}
.roadmap-table {
  display: flex;
}
.roadmap-table * {
  text-align: center;
  text-transform: uppercase;
}

.roadmap-table-inner {
  padding: 25px;
  width: 33%;
}

.roadmap-table-inner:last-child {
  border-bottom: none;
}
.milestone-header {
  color: #888;
  font-weight: 600;
  letter-spacing: 1px;
}
.roadmap-features {
  font-weight: 600;
  letter-spacing: 1px;
  margin: 40px 0 25px;
  list-style-type: none;
}

.roadmap-features-item {
  border-top: 1px solid #202124;
  font-size: 12px;
  line-height: 1.5;
  padding: 15px 0;
}

.roadmap-features-item:last-child {
  border-bottom: 1px solid #202124;
}

.roadmap-price {
  color: #016FF9;
  display: block;
  font-size: 32px;
  font-weight: 700;
}

.roadmap-button {
  border: 1px solid #9dd1ff;
  border-radius: 10px;
  color: #348EFE;
  display: inline-block;
  margin: 25px 0;
  padding: 15px 35px;
  text-decoration: none;
  transition: all 150ms ease-in-out;
}

.roadmap-button:hover,
.roadmap-button:focus {
  background-color: #e1f1ff;
}

.roadmap-button.is-featured {
  background-color: #48aaff;
  color: #fff;
}

.roadmap-button.is-featured:hover,
.roadmap-button.is-featured:active {
  background-color: #269aff;
}
.page-wrapper{
    background-color: #202124;
}

.left-sidebar{
    background-color: #292a2d;
}

.nav-tabs .nav-link.active {
     background-color: #292a2d
}

.nav-tabs{
    border-bottom-color: #4c4c4c;
}

.page-titles{
    background-color:  #202124;
}

.table-hover tbody tr:hover {
    background: #202124;
}
.table td{
    vertical-align: unset;
}
.table td, .table th {
    border-color: #4c4c4c;
}
.table tbody+tbody {
    border-top: 2px solid #4c4c4c;
}
html{
    background-color: #202124;
}
body{
    color: #babcbd
}

h1, h2, h3, h4, h5, h6{
    color: #babcbd
}

.card {
    background-color: #292a2d;
}

.sidebar-nav ul li a, .sidebar-nav ul li a.active, .sidebar-nav ul li a:hover {

    color: #babcbd
}
.img-thumbnail{
    background-color: #292a2d;
    border: 1px solid #292a2d;
}

.preloader{
    background-color: #202124;
}

.error {
    color: red;
}

.floating-labels .focused label {
    color: white !important;
}
.form-control, .form-control:focus{
    background-color: unset;
    color: #babcbd;
}



html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
